import {FormFiltriDPC, PageContainerDPC} from '@dpc-fe/shared';
import React from 'react';
import {graphql} from "gatsby";
import {buildMenu} from "@dpc-fe/shared/js/shared-functions";
import SvgIconDPC from "@dpc-fe/shared/components/dpc-svg-icon";
import {prop, getCategoriaPrimariaEN} from "../functions/functions";

export default ({data}) => {
    const language = 'en';
    const menu = buildMenu(data.menu.edges, language, process.env.ENV, process.env.PORTALE_URL, null, prop).filter(item => item.title.toLowerCase() !== 'homepage');
    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={data.menu.edges}
                          slugTraduzione={'sitemap'}
                          title={'Sitemap'}
                          env={process.env.ENV}
                          lang={language}
                          minisiti={prop}
                          breadcrumbItem={{
                              name: 'Sitemap',
                              slug: 'en/sitemap',
                              active: true
                          }}
                          menuFooterItems={data.menuFooter.edges}
        >
            <section className="container sitemap">
                <div className="px-custom">
                    <h1>Sitemap</h1>
                    <div className="row">
                        {Array.isArray(menu) && menu.sort((a, b) => a.weight > b.weight ? 1 : -1).map((item) => {
                            return (
                                <div className="col-md-4">
                                    <div className="link-list-wrapper">
                                        <h2>{item.title}</h2>
                                        {
                                            item.children.length > 0 && (
                                                <ul className="link-list">
                                                    {item.children.sort((a, b) => a.weight > b.weight ? 1 : -1).map((child, index) => {
                                                            return (
                                                                <li>
                                                                    <div className="a-link-and-button-collapse">
                                                                        <a className="list-item"
                                                                           href={child.link}><span>{child.title}</span></a>
                                                                        {
                                                                            child.children.length > 0 && (
                                                                                <button type="button"
                                                                                        className="btn btn-icon right-icon collapsed"
                                                                                        title="Espandi menu di navigazione"
                                                                                        data-toggle="collapse"
                                                                                        data-target={`#collapse_sitemap_${index}_${item.id}`}
                                                                                        aria-controls={`collapse_sitemap_${index}_${item.id}`}
                                                                                        aria-expanded="false">
                                                                                    <SvgIconDPC name={'expand'}/>
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        child.children.length > 0 && (
                                                                            <ul className="link-sublist collapse"
                                                                                id={`collapse_sitemap_${index}_${item.id}`}>
                                                                                {child.children.map(element => (
                                                                                    <li>
                                                                                        <a className="list-item"
                                                                                           href={element.link}><span>{element.title}</span></a>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </PageContainerDPC>
    );
};

export const query = graphql`
  {
     menu: allTaxonomyTermMenuPortaleEn {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__notizia {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_senza_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_con_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_approfondimenti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_podcast {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_notizie_comunicati_stampa {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_pubblicazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_normative {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_bandi_di_gara_e_contratti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_foto_e_video {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_volontariato {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_rischi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_ser_nazionale {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_relazioni {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_mappe {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_scuola_giovani {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_formazione {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_eventi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_emergenze {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_domande {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_docs {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_pnrr {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_it_alert {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                }
                parent {
                  name
                  drupal_internal__tid
                }
              }
              drupal_internal__tid
            }
          }
        },
        menuFooter: allTaxonomyTermMenuFooterEn {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                }
              }
              drupal_internal__tid
            }
          }
        }
  }
`;
